<template>
  <div v-if="modalActive" ref="modalBLockWrapper" class="Modal__wrapper" @click="close" />
  <div v-if="modalActive" ref="modalBLock" class="Modal_slider">
    <div class="Modal__inner">
      <p class="modal_slider_header">more</p>
      <slot />
      <div class="button" @click="close">done</div>
    </div>
  </div>
</template>

<script>
// import {defineComponent, ref, watch} from 'vue';
import { defineComponent } from 'vue';

export default defineComponent({
  name: 'Modal',
  props: ['modalActive'],
  emits: ['close'],
  setup(props, { emit }) {
    const close = () => {
      emit('close');
    };
    // const modalTop = ref('45px');
    // const modalBLock = ref(null);
    // const modalBLockWrapper = ref(null);

    // watch(() => modalBLock.value, (newValue, oldValue) => {
    //     if (newValue) {
    //       let blockHeight = modalBLock.value.clientHeight;
    //       let distance = getElementDistanceToEnd(newValue.parentElement);
    //       if (distance - blockHeight < document.getElementsByTagName('footer')[0].clientHeight + newValue.parentElement.clientHeight) {
    //         modalTop.value = (-blockHeight).toString() + 'px';
    //       } else {
    //         modalTop.value = newValue.parentElement.clientHeight.toString() + 'px';
    //       }
    //     }
    // });

    // const getElementDistanceToEnd = (element) => {
    //   const elementRect = element.getBoundingClientRect();
    //   const viewportHeight = window.innerHeight;

    //   return viewportHeight - elementRect.top;
    // }

    // return { close, modalTop, modalBLock, modalBLockWrapper };
    return { close };

  },
});
</script>

<style lang="scss">
.Modal {
  font-size: 0.75rem;
  color: #444;
  line-height: 16px;
  min-width: 166px;
  position: absolute;
  right: 0;
  top: v-bind(modalTop);//37px;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  flex-wrap: nowrap;
  background-color: #ffffff;
  padding: 6px 16px;
  border: 1px solid rgba(37, 37, 37, 0.1);
  box-shadow: 0px 6px 20px rgba(37, 37, 37, 0.04);
  border-radius: 8px;
  z-index: 20;
  &__wrapper {
    position: fixed;
    background-color: rgba(0, 0, 0, 0.7);
    top: 0px;
    left: 0px;
    bottom: 0px;
    right: 0;
    z-index: 15;
    width: 100%;
    height: auto;
  }
  &__inner {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    flex-wrap: nowrap;
    width: 100%;
  }
  @media screen and (max-width: 599px) and (orientation: portrait),
  screen and (max-height: 500px) and (orientation: landscape) {
    min-width: auto;
  }
}
.Modal_slider{
  position: fixed;
  top: 0px;
  bottom: 0px;
  right: 0px;
  width: calc(100% - 1300px);
  min-width: 404px;
  max-width: 700px;
  box-sizing: border-box;
  padding: 0 30px 30px 30px;
  background-color: #fff;
  color: #444;
  font-size: 1rem;
  z-index: 20;
  box-shadow: inset 0px 17px 8px -15px #0000000F,inset 0px -17px 8px -15px #0000000F;
  .modal_slider_header{
    height: 48px;
    line-height: 48px;
    font-size: 1rem;
    font-weight: 500;
    text-align: center;
    color: #444;
    text-transform: uppercase;
    position: relative;
    width: 100%;
    @media screen and (max-width: 599px) and (orientation: portrait),
    screen and (max-height: 500px) and (orientation: landscape) {
      margin: 0px;
    }
  }
  .modal_slider_header::before,.modal_slider_header::after{
    content: '';
    width: calc(50% - 60px);
    height: 5px;
    position: absolute;
    background-color: #2525251A;
    top: 22px;
    border-radius: 3px;
  }
  .modal_slider_header::before{
    left: 15px;
  }
  .modal_slider_header::after{
    right: 15px;
  }
  .Modal__inner{
    height: 100%;
  }
  .button{
    width: 100%;
    height: 42px;
    line-height: 42px;
    margin-top: auto;
    position: relative;
  }
  .button::before{
    content: '';
    position: absolute;
    height: 3px;
    border-radius: 1.5px;
    width: 100%;
    top: -28px;
    left: 0px;
    right: 0px;
    background-color: #2525251A;
  }
}
@media screen and (max-width: 599px) and (orientation: portrait),
screen and (max-height: 500px) and (orientation: landscape){
  .Modal_slider{
    min-width: auto;
    max-width: none;
    width: 100%;
    top: initial;
    bottom: 0px;
    right: 0px;
    border-radius: 16px 16px 0 0;
    padding: 0px 16px 16px 16px;
    .button{
      margin-top: 34px;
    }
  }
}
</style>
